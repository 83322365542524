<template>
  <v-dialog v-model="active" width="800">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="info">New Housing Request</v-btn>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Housing Request</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height:575px;padding-top:10px">
        <v-alert v-if="current.bldg === 'STUAPT'" type="info">Since you currently live in the apartments, you cannot choose to stay in your room. Please choose another option below.</v-alert>
        <v-alert v-else-if="current.bldg === 'OFFHOU'" type="info">Since you currently live off-campus, you cannot choose to stay in your room. Please choose another option below.</v-alert>
        <form-radio v-model="phaseCode" :items="phaseOptions" label="I want to..."></form-radio>
        <h3 v-if="phaseCode === 'stay'" style="margin-bottom: 1em">Rooming in {{ current.room }}</h3>
        <v-autocomplete v-if="phaseCode === 'change'" v-model="room" :items="rooms" :search-input.sync="roomSearch" item-text="search" item-value="room" label="Select the room you want to live in" no-filter outlined>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.search }}</v-list-item-title>
              <v-list-item-subtitle>Capacity: {{ item.capacity }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <p v-if="phaseCode === 'apartment'">The apartments are available to groups of 4 students, and will be assigned based on the highest cumulative class level (i.e. a group of 4 seniors will have priority over a group of 2 seniors and 2 juniors). Please select 3 students to include as roommates for your apartment application.</p>
        <template v-else-if="phaseCode === 'study-abroad' && !studyAbroad">
          <p>You do not currently have a study abroad application for next fall. Please complete your study abroad application by following the link below then return here to submit your housing form.</p>
          <v-btn to="/student/study-abroad">Go To Study Abroad Application</v-btn>
        </template>
        <roommates v-if="phaseCode === 'stay' || phaseCode === 'change' || phaseCode === 'apartment'" v-model="roommates" :sex="sex" :capacity="roomCapacity" :is-apartment="phaseCode === 'apartment'"></roommates>
        <v-row v-if="phaseCode === 'off-campus'">
          <v-col :cols="12">
            <v-select v-model="address.exception" :items="offCampusExceptions" label="Reason for Exception" outlined hide-details></v-select>
          </v-col>
          <v-col :cols="12">
            <v-text-field v-model="address.address" label="Street Address" outlined hide-details></v-text-field>
          </v-col>
          <v-col :cols="12" md="4">
            <v-text-field v-model="address.city" label="City" outlined hide-details></v-text-field>
          </v-col>
          <v-col :cols="12" md="4">
            <v-select v-model="address.state" :items="states" label="State" outlined hide-details></v-select>
          </v-col>
          <v-col :cols="12" md="4">
            <v-text-field v-model="address.zip" label="Zip" outlined hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="phaseCode === 'off-campus' || phaseCode === 'apartment'">
          <v-col>
            <!-- <p>Meal plan participation is optional for students living in the apartments or off campus. If you would like to purchase a meal plan, please select one from the drop-down menu below. Note that your selection will automatically apply to both the Fall and Spring semesters, though the plans reset at the end of each semester with no roll-over. If you desire to purchase a meal plan for the Fall semester only, please make your selection below and then visit the Student Development Office in December to remove the Spring plan from your account. Please reference the College's Tuition & Fees Schedule for meal plan pricing.</p> -->
            <p>Please choose a meal plan option below, which will apply to both semesters next year. Meal Plan participation is optional for students living in the apartments or off campus. See the <a href="https://www.covenant.edu/pdf/finaid/FY25_Tuition_and_Fees_Schedule.pdf" target="_blank">Tuition & Fees Schedule</a> for meal plan pricing.</p>
            <v-select v-model="mealPlan" :items="mealPlanOptions" label="Meal Plan" outlined hide-details></v-select>
            <p v-if="phaseCode === 'apartment'" class="ma-4">Students with vehicles living in the apartments are required to purchase a Zone 3 (Shadowlands) or Zone 4 (Apartments) permit. A Zone 4 permit is required to park in the apartment parking lot. Purchasing permits for the fall will become available late in the summer.</p>
            <p v-else class="ma-4">Commuters driving to campus are required to buy a Commuter or a Zone 3 (Shadowlands) permit. Purchasing permits for the fall will become available late in the summer.</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions style="margin-top:16px">
        <v-btn :disabled="!isValid" color="primary" @click="submit">{{ isSmall ? 'Submit' : 'Submit Housing Application' }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined @click="active = false">{{ isSmall ? 'Close' : 'Close Housing Form' }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.select-card {
  cursor: pointer;
}
</style>
<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
export default {
  components: {
    formRadio: () => import('@/components/forms/inputs/Radio'),
    roommates: () => import('./roommates')
  },
  props: {
    current: {
      type: Object,
      default: () => {
        return {
          room: 'FB112',
          hall: 'First Belz',
          bldg: 'FOUNDB',
          building: 'Founders Hall',
          capacity: 2
        }
      }
    },
    currentRoommates: {
      type: Array,
      default: () => {
        return []
      }
    },
    term: {
      type: String,
      required: true
    },
    sex: {
      type: String,
      required: true
    },
    studyAbroad: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const isSmall = computed(() => root.$vuetify.breakpoint.name === 'xs' || root.$vuetify.breakpoint.name === 'sm')
    const active = ref(false)
    const roommates = ref([])
    watch(() => props.currentRoommates, () => {
      if (props.currentRoommates.length > 0) {
        roommates.value = []
        for (const roommate of props.currentRoommates) {
          roommates.value.push(roommate)
        }
      }
    })
    const phaseOptions = ref([
      { value: 'stay', text: 'Stay in my room', icon: 'fal fa-bed', disabled: true },
      { value: 'apartment', text: 'Apply to live in the apartments', icon: 'fal fa-building', disabled: true },
      { value: 'change', text: 'Change rooms', icon: 'fal fa-door-open', disabled: true },
      { value: 'off-campus', text: 'Apply to live off campus', icon: 'fal fa-car-building', disabled: true },
      { value: 'study-abroad', text: 'Study abroad this fall', icon: 'fal fa-globe-europe', disabled: true }
    ])
    // watch(() => props.current, () => {
    //   if (props.current.bldg === 'STUAPT' || props.current.bldg === 'OFFHOU') {
    //     phaseOptions.value.splice(0, 1, { ...phaseOptions.value[0], disabled: true })
    //   }
    // })
    const availableOptions = ref([])
    onMounted(async () => {
      const { data } = await root.$feathers.service('system/term').find({ query: { term: props.term } })
      if (data.length === 0) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Term data not found. Please contact Tech Services for assistance.' })
        active.value = false
        return
      }
      if ('housing' in data[0]) {
        const now = new Date()
        const { housing: { start, end, options } } = data[0]
        if (new Date(start) > now) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Housing requests are not yet active for the selected term.' })
          active.value = false
        } else if (new Date(end) < now) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Housing requests have closes for the selected term. Please contact Student Development directly for any housing requests.' })
          active.value = false
        } else {
          availableOptions.value = options.filter(({ open }) => new Date(open) < now).map(({ option }) => option)
          for (let i = 0; i < phaseOptions.value.length; i++) {
            if (availableOptions.value.includes(phaseOptions.value[i].value)) {
              // If the option is to stay and they are in the student apartments or housing, don't update. Otherwise set the disabled option to false
              if (!(phaseOptions.value[i].value === 'stay' && ['STUAPT', 'OFFHOU'].includes(props.current.bldg))) {
                phaseOptions.value.splice(i, 1, { ...phaseOptions.value[i], disabled: false })
              }
            } else {
              phaseOptions.value.splice(i, 1, { ...phaseOptions.value[i], text: phaseOptions.value[i].text + ' (phase not yet available)' })
            }
          }
        }
      }
    })
    const phaseCode = ref('')
    const room = ref('')
    const roomCapacity = ref(0)
    watch(phaseCode, () => {
      switch (phaseCode.value) {
        case 'stay':
          root.$feathers.service('student/housing-room').find({ query: { room: props.current.room } }).then(({ data }) => {
            const arr = []
            for (const { search, room, data: termData } of data) {
              for (let i = termData.length - 1; i >= 0; i--) {
                if (termData[i].term === props.term) {
                  arr.push({ search, room, capacity: termData[i].capacity })
                  break
                }
              }
            }
            rooms.value = arr
            room.value = props.current.room
          })
          break
        case 'apartment':
          room.value = ''
          roomCapacity.value = 4
          break
        case 'change':
          if (room.value !== '') {
            for (const { room: dataRoom, capacity } of rooms.value) {
              if (room.value === dataRoom) {
                roomCapacity.value = capacity
                break
              }
            }
          }
          break
        case 'off-campus':
        case 'study-abroad':
          // console.log('Setting roommates to empty array')
          roomCapacity.value = 0
          roommates.value = []
          break
      }
    })
    const address = ref({
      exception: '',
      address: '',
      city: '',
      state: '',
      zip: ''
    })
    const offCampusExceptions = ref(['I will be living at home with my parents', 'I am married', 'I will be over 25 years old at the start of the fall semester', 'I will be part-time this fall (11 or fewer credit hours)', 'I will be a senior (have completed 90+ hours)', 'I will be a junior who has lived on campus for six semesters'])
    const states = ref([{ text: 'Alabama', value: 'AL' }, { text: 'Georgia', value: 'GA' }, { text: 'Tennessee', value: 'TN' }])
    const mealPlan = ref(null)
    const mealPlanOptions = ref([
      { text: 'No Meal Plan', value: '' },
      { text: 'Commuter 1 meal plan (65 meals per semester)', value: 'C1MP' },
      { text: 'Commuter 2 meal plan (130 meals per semester)', value: 'C2MP' },
      { text: 'Full meal plan', value: 'MFUL' }
    ])
    watch(room, (val) => {
      if (val != null && val !== '') {
        for (const { room: dataRoom, capacity } of rooms.value) {
          if (room.value === dataRoom) {
            roomCapacity.value = capacity
            break
          }
        }
      }
    })

    async function submit () {
      /*
        Submission object: {
          status: String,
          phase: Number,
          room: String,
          hall: String,
          building: String,
          roommates: [{
            pidm: String,
            name: String,
            status: String
          }],
          offCampus: {
            address: String,
            city: String,
            state: String,
            zip: String
          }
        }
      */
      let hall = ''
      let building = ''
      const { data } = await root.$feathers.service('student/housing-room').find({ query: { room: room.value, $populate: 'hallId' } })
      if (data.length > 0) {
        hall = data[0].hallId.name
        building = data[0].hallId.building
      }
      let phase = 4
      let status = 'Proposed'
      if (phaseCode.value === 'stay' || room.value === props.current.room) phase = 1
      else if (hall === props.current.hall) phase = 2
      else if (building === props.current.building) phase = 3
      else if (phaseCode.value === 'apartment') {
        hall = 'Student Apartments'
        building = 'Student Apartments'
        phase = 8
      } else if (phaseCode.value === 'off-campus') {
        phase = 9
        building = 'Off-Campus Housing'
        status = 'Pending'
      } else if (phaseCode.value === 'study-abroad') {
        phase = 10
        building = 'Study Abroad'
        status = 'Pending'
      }
      const request = {
        term: props.term,
        startedBy: user.value._id,
        status,
        phase,
        option: phaseCode.value,
        room: room.value,
        hall,
        building,
        roommates: [{
          category: 'student',
          pidm: user.value.pidm,
          name: user.value.preferred + ' ' + user.value.last,
          approved: true
        }]
      }
      if (phaseCode.value === 'off-campus') {
        request.offCampus = address.value
        if (mealPlan.value !== '') request.roommates[0].mealPlan = mealPlan.value
      } else if (phaseCode.value === 'apartment' && mealPlan.value !== '') {
        request.roommates[0].mealPlan = mealPlan.value
      }
      let isComplete = true
      for (const { category, text, name, value } of roommates.value) {
        let obj = { category, name }
        switch (category) {
          case 'student':
            obj.name = text
            const { pidm } = await root.$feathers.service('directory/people').get(value)
            obj.pidm = pidm
            isComplete = false
            break
          case 'unknown':
            obj.name = 'No planned roommate'
            break
        }
        if (isComplete) request.status = 'Pending'
        request.roommates.push(obj)
      }
      try {
        await root.$feathers.service('student/housing-request').create(request)
        root.$store.dispatch('main/snackbar', { text: 'Housing request saved', color: 'success', timeout: 6000, active: true })
        emit('saved')
        active.value = false
      } catch (e) {
        root.$store.dispatch('main/snackbar', { text: 'Error saving housing request: ' + e, color: 'error', timeout: 8000, active: true })
      }
    }

    const rooms = ref([])
    const roomSearch = ref('')
    watch(roomSearch, (val) => {
      let query = { hallCode: { $ne: 'APT' }, data: { $elemMatch: { term: props.term, sex: props.sex, status: { $in: ['Open', 'Pending'] } } }, $sort: { room: 1 }, $limit: 20 }
      if (!availableOptions.value.includes('change')) {
        if (availableOptions.value.includes('change:bldg')) {
          if (val == null || val === '') val = props.current.building
          else val = props.current.building + ' ' + val
        } else if (availableOptions.value.include('change:hall')) {
          query.hallName = props.current.hall || ''
        }
      }
      if (val != null && val !== '') {
        query.search = { $regex: val.replaceAll(' ', '[\\s\\w\\-]*'), $options: 'i' }
      }
      root.$feathers.service('student/housing-room').find({ query }).then(({ data }) => {
        const arr = []
        for (const { search, room, data: termData } of data) {
          for (let i = termData.length - 1; i >= 0; i--) {
            if (termData[i].term === props.term) {
              arr.push({ search, room, capacity: termData[i].capacity })
              break
            }
          }
        }
        rooms.value = arr
      })
    })

    const isValid = computed(() => {
      if (roommates.value.length > 0) {
        // If the category is student and no student is selected, then this is invalid
        // If the category is applicant and there is no name entered, then this is invalid
        for (const { category, name, value } of roommates.value) {
          if (!category) return false
          switch (category) {
            case 'student':
              if (!value || value === '') return false
              break
            case 'applicant':
              if (!name || name === '') return false
              break
          }
        }
      }
      switch (phaseCode.value) {
        case 'change':
        case 'stay':
          if (room.value === '') return false
          if (roomCapacity.value > 0) {
            // Not enough roommates in the roommate array
            if (roommates.value.length < roomCapacity.value - 1) return false
            // Check that each roommate has a status
          }
          break
        case 'apartment':
          if (roommates.value.length < 3) return false
          break
        case 'off-campus':
          return address.value.exception !== '' && address.value.address !== '' && address.value.city !== '' && address.value.state !== '' && address.value.zip !== ''
        case 'study-abroad':
          return props.studyAbroad
        default:
          return false
      }
      return true
    })

    return {
      user,
      isSmall,
      active,
      phaseOptions,
      phaseCode,
      room,
      address,
      offCampusExceptions,
      states,
      mealPlan,
      mealPlanOptions,
      roomCapacity,
      roommates,
      submit,
      rooms,
      roomSearch,
      isValid
    }
  }
}
</script>
